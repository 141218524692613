<template>
  <div class="tasks-list">
    <Banner>
      <div class="banner-content">
        <MeepIconTask
          class="banner-content__icon banner-content__icon-stroke"
        />
        <span class="banner-content__text">{{ $t("menu.task") }}</span>
      </div>
    </Banner>
    <!-- La barre d'action -->
    <PageHeader
      v-model="query"
      :active-tab="currentTab"
      :has-actions="isAllowed"
      :has-search="true"
      :sync-tab="true"
      :tabs="tabs"
      @back="goBack"
    >
      <template #right>
        <md-button
          :class="{
            'md-primary': !isJEP,
            'md-alternate': isJEP,
          }"
          class="md-raised header-action header-action-icon"
          @click="onCreate"
        >
          <MeepIconActionAdd class="app-icon-stroke-inverted" />
        </md-button>
      </template>
    </PageHeader>
    <div class="tasks-list-content page-layout">
      <LoadingCard v-if="isLoading" />

      <md-card v-if="showCompanies" class="page-list list-companies">
        <md-list class="md-double-line">
          <md-list-item
            v-for="(_company, index) in companyFilter"
            :key="index"
            @click="viewCompanyTask(_company)"
          >
            <div class="md-list-item-icon">
              <MeepIconSettingCompanies class="app-icon" />
            </div>

            <div class="md-list-item-text">
              <span class="company-name">
                {{ _company.name }}
              </span>
            </div>
          </md-list-item>

          <md-empty-state
            v-if="companyFilter.length === 0"
            :md-label="$t('task.table.no-company-task')"
          >
          </md-empty-state>
        </md-list>
      </md-card>

      <sortable-list
        v-if="!showCompanies"
        :has-pagination="true"
        :items="viewingTasks"
        :not-found-text="
          showHistoryTask ? '' : $t('tasks.table.not-found-click-text')
        "
        :notFoundClick="onCreate"
        :notFoundLabel="notFoundLabel"
        :page-size="5"
        :parameters="listParameters"
        :selectable="'single'"
        class="page-list"
        default-sort="name"
        link="/dashboard/tasks/view/"
      />
    </div>
  </div>
</template>

<script>
import tasksModel from "../../model/tasks";
import companiesModel from "../../model/companies";
import Banner from "@/components/Banner";
import MeepIconTask from "@/components/icons/MeepIconTask.vue";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import Fuse from "fuse.js";
import LoadingCard from "@/components/LoadingCard";
import { sortByKey } from "@/services/util";

export default {
  name: "ListTask",

  components: {
    Banner,
    MeepIconTask,
    LoadingCard,
    PageHeader,
    MeepIconActionAdd,
    MeepIconSettingCompanies,
  },

  data() {
    return {
      query: "",
      ownTasks: [],
      companyTasks: [],
      historyTasks: [],
      companies: [],
      companyId: null,
      company: null,
      pageSize: 10,
      pageCount: 0,
      page: 1,
      currentTab: "",
      isLoading: false,
      selectedList: [],

      tabs: [
        {
          id: "tab-own",
          label: "Mes tâches",
          to: "/dashboard/tasks/tab-own",
        },
        {
          id: "tab-all",
          label: "Toutes",
          to: "/dashboard/tasks/tab-all",
        },
        {
          id: "tab-history",
          label: "Historique",
          to: "/dashboard/tasks/tab-history",
        },
      ],
    };
  },

  created() {
    this.loadTask();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    goBackListCompany() {
      this.company = null;
      this.companyId = null;

      this.$router.push({
        path: "/dashboard/tasks/tab-all",
      });
    },

    viewCompanyTask(company) {
      this.$router.push({
        path: "/dashboard/tasks/tab-all/" + company.id,
      });
    },

    onSelect(items) {
      Promise.all(
        items.map(item => {
          item.status = !item.status;
          this.statusToggle(item);
        }),
      );
    },

    async statusToggle(item) {
      try {
        await tasksModel.update(item);

        if (item.status) {
          this.$toasted.global.AppSucces({
            message: this.$t("task.complete-check"),
          });
        } else {
          this.$toasted.global.AppSucces({
            message: this.$t("task.complete-uncheck"),
          });
        }

        if (this.currentTab === "tab-own") {
          this.ownTasks = this.ownTasks.filter(task => task.id !== item.id);
        }

        if (this.currentTab === "tab-all") {
          this.companyTasks = this.companyTasks.filter(
            task => task.id !== item.id,
          );
        }

        if (this.currentTab === "tab-history") {
          this.historyTasks = this.historyTasks.filter(
            task => task.id !== item.id,
          );
        }
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    mapTask(task) {
      task.name = task.firstname + " " + task.lastname;
      task.complete = task.status ? 1 : 0;
      return task;
    },

    async loadTask() {
      this.companyId = this.$route.params.companyId;
      this.currentTab = this.$route.params.tab || "tab-all";

      try {
        this.isLoading = true;

        if (this.currentTab === "tab-own") {
          this.ownTasks = [];
          const tasks = await tasksModel.getAllIncomplete();
          this.ownTasks = tasks.map(this.mapTask);
        }

        if (this.currentTab === "tab-all") {
          if (this.companyId) {
            this.companyTasks = [];
            this.company = await companiesModel.get(this.companyId);
            const tasks = await tasksModel.getAllIncompleteByCompany({
              company_id: this.companyId,
            });
            this.companyTasks = tasks.map(this.mapTask);
          } else {
            this.company = null;

            if (this.companies.length === 0) {
              const companies = await companiesModel.getAll();

              this.companies = sortByKey(companies, "name");
            }
          }
        }

        if (this.currentTab === "tab-history") {
          this.historyTasks = [];
          const tasks = await tasksModel.getAllComplete();
          this.historyTasks = tasks.map(this.mapTask);
        }

        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    onCreate() {
      this.$router.push("/dashboard/tasks/create");
    },
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isJEP"]),

    currentList() {
      if (this.showOwnTask) {
        return this.ownTasks;
      }

      if (this.showCompanyTask) {
        return this.companyTasks;
      }

      if (this.showHistoryTask) {
        return this.historyTasks;
      }

      return [];
    },

    listParameters() {
      return [
        {
          name: this.$t("tasks.table.title-label"),
          key: "title",
          class: "primary-item",
        },
        {
          name: this.$t("tasks.table.date-label"),
          key: "deadline",
          format: this.$$filters.formatTimestamp,
        },
        {
          name: this.$t("tasks.table.assignee-label"),
          key: "name",
        },
        {
          name: this.$t("tasks.table.status-label"),
          key: "status",
          checkbox: true,
          checkBoxAction: item => {
            this.statusToggle(item);
          },
        },
      ];
    },

    companyFilter() {
      if (this.query === "") {
        return this.companies;
      }

      const fuse = new Fuse(this.companies, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["name", "siren"],
      });

      return fuse.search(this.query).map(item => item.item);
    },

    filter() {
      if (this.query === "") {
        return this.currentList;
      }

      const fuse = new Fuse(this.currentList, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["title", "firstname", "lastname"],
      });

      return fuse.search(this.query).map(item => item.item);
    },

    isAllowed() {
      return this.isAdmin || this.isCollab;
    },

    showCompanyTask() {
      return (
        !this.isLoading &&
        this.currentTab === "tab-all" &&
        this.company !== null
      );
    },

    showCompanies() {
      return (
        !this.isLoading &&
        this.currentTab === "tab-all" &&
        this.company === null
      );
    },

    showOwnTask() {
      return !this.isLoading && this.currentTab === "tab-own";
    },

    showHistoryTask() {
      return !this.isLoading && this.currentTab === "tab-history";
    },

    viewingTasks() {
      if (this.showCompanyTask) {
        return this.companyTasks;
      }

      if (this.showHistoryTask) {
        return this.historyTasks;
      }

      return this.ownTasks;
    },

    notFoundLabel() {
      return this.showHistoryTask
        ? this.$t("tasks.table.not-found-history-label")
        : this.$t("tasks.table.not-found-own-label");
    },
  },

  watch: {
    $route: "loadTask",
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .tasks-list {
    &__task-title {
      font-weight: bold;
    }

    .meep-table {
      margin: 30px;
    }

    .table-pagination {
      border: 0;
      height: 56px;
      font-size: 12px;
      margin-top: 10px;
      text-align: center;
    }

    .md-hidden {
      display: none;
    }

    .tasks-list-content.page-layout {
      margin: 0 toRem(29) 0 toRem(29);
      padding: 0;
      @include for-lg {
        padding: toRem(0) toRem(44) toRem(8) toRem(54);
        margin: 0;
      }
    }

    .sortable-list {
      padding-top: 0;
      @include for-lg {
        padding-top: 0;
      }
    }

    .page-header {
      padding: 0 toRem(29);
      @include for-lg {
        padding: 0 toRem(44) 0 toRem(54);
      }

      .page-header__tabs-search {
        .page-header__utils {
          .md-button {
            margin-right: 0;
          }
        }
      }
    }

    .page-list {
      .md-list {
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;
        padding: 0;

        .md-list-item {
          .company-name {
            font-family: var(--font-extrabold);
            font-size: 12px;

            @include for-lg {
              font-size: 20px;
            }
          }

          &-button {
            height: 63px;
            box-sizing: border-box;
            border-bottom: 1px solid var(--bg-secondary);
            @include for-lg {
              height: 100px;
            }

            .md-list-item-content {
              min-height: unset;
            }
          }

          &:first-child {
            .md-list-item-button {
              border-top-left-radius: 11px;
              border-top-right-radius: 11px;
            }
          }

          &-content {
            padding-left: 0px;
          }

          &-icon {
            width: 32px;
            height: 32px;
            margin: 0 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--bg-third, #000);
            border-radius: 50%;

            .app-icon {
              width: 18px;
              height: 18px;
              @include for-lg {
                width: 25px;
                height: 25px;
              }

              path {
                fill: var(--text-primary, #fff) !important;
              }
            }

            @include for-lg {
              width: 47px;
              height: 47px;
            }
          }
        }
      }
    }

    .sortable-list {
      .no-item {
        .md-primary {
          width: auto;
        }
      }
    }

    &-content {
      &.page-layout {
        height: 100%;
      }

      .md-icon-image.md-table-sortable-icon {
        right: 0;
        left: unset;
      }

      .md-theme-default.md-table
      .md-table-head:nth-child(2)
      .md-table-head-label
      .md-table-sortable-icon {
        right: 0;
        left: unset;
      }
    }
  }
}
</style>
